.background {
  background-color: #F4F4F4;
  height: auto;
  padding-top: 30px;
  width: auto;
  .news {
    background-image: url("../../assets/guitar.svg");
    background-repeat: no-repeat;
    background-size: auto;
    padding-bottom: 119px;
    text-align: center;
    
    &__header {
      text-align: center;
      padding-top: 75px;
      .news__title{
        margin-bottom: 32px;
      }
      p{
        margin-top: 19px;
        margin-bottom: 74px;
      }
      
    }
    

    &__container {
      display: grid;
      grid-template-columns: 1fr 1fr 1fr;
      grid-template-rows: 1fr 1fr;
      justify-content: stretch;
      align-items: stretch;
      .newsCard {
        text-align: center;
        margin-bottom: 80px;
        position: relative;
        display: flex;
        flex-direction: column;
        align-items: center;
        height: 570px;
        h3 {
          font-size: 25px;
          font-weight: 700;
          line-height: 30px;
          margin-top: 40px;
        }

        p {
          font-size: 20px;
          font-weight: 400;
          line-height: 26px;
          margin-top: 39px;
        }

        a {
          display: block;
          position: absolute;
          bottom: 0;
        }
      }
      @media (max-width: 1800px) {
        .newsCard{
          height: 520px;
          &__image{
            img{
              width: 405px;
              height: 238px;
            }
          }
        }
      }
      @media (max-width: 1500px) {
        .newsCard{
          &__image{
            img{
              width: 365px;
              height: 218px;
            }
          }
          h3 {
            font-size: 23px;
            margin-top: 30px;
          }
  
          p {
            font-size: 18px;
            margin-top: 34px;
          }
  
          button {
            margin-top: 23px;
            font-size: 18px;
          }
        }
      }
      @media (max-width: 1350px) {
        .newsCard{
          height: 480px;
          &__image{
            img{
              width: 325px;
              height: 198px;
            }
          }
          h3 {
            font-size: 21px;
            margin-top: 25px;
          }
  
          p {
            font-size: 16px;
            margin-top: 30px;
          }
  
          button {
            margin-top: 20px;
            font-size: 16px;
          }
        }
      }
      @media (max-width: 1200px) {
        .newsCard{
          height: 460px;
          &__image{
            img{
              width: 285px;
              height: 178px;
            }
          }
          h3 {
            font-size: 19px;
            margin-top: 25px;
          }
  
          p {
            font-size: 16px;
            margin-top: 26px;
          }
  
          button {
            margin-top: 19px;
            font-size: 16px;
          }
        }
      }
    
    
    }
    
    &__button{
      margin-top: 113px;
    }
    @media (max-width: 1800px) {
      &__button{
        margin-top: 73px;
      }
    }
    @media (max-width: 1500px) {
      &__button{
        margin-top: 45px;
      }
    }
    @media (max-width: 1350px) {
      &__button{
        margin-top: 25px;
        font-size: 16px;
      }
    }
    @media (max-width: 425px) {
      &__button{
        margin-top: 15px;
        font-size: 15px;
      }
    }
    @media (max-width: 375px) {
      &__button{
        margin-top: 10px;
        font-size: 14px;
      }
    }
    @media (max-width: 320px) {
      &__button{
        margin-top: 10px;
        font-size: 13px;
      }
    }
  }
  
  
}
@media (max-width: 1024px) {
  .background{
    background-color: #F4F4F4;
    height: auto;
    padding-top: 60px;
      .news{
        background-image: none;
        text-align: center;
        padding-bottom: 69px;
        &__header{
          text-align: center;
          padding-top: 75px;
          
        }
        @media (max-width: 840px) {
          &__header{
            padding-top: 45px;
          }
        }
        &__container {
          display: flex;
          flex-direction: column;
          justify-content: flex-start;
          align-content: center;
          text-align: center;
          align-items: center;
          .newsCard {
            width: 90%;
            position: relative;
            display: flex;
            flex-direction: column;
            align-items: center;
            height: 520px;
            text-align: center;
            margin-bottom: 55px;
            &__image{
              margin: 0px;
              img{
                width: 550px;
                height: 300px;
              }
            }
            @media (max-width: 590px) {
              &__image{
                img{
                  width: 420px !important;
                  height: 300px !important;
                }
              }
            }
            @media (max-width: 540px) {
              &__image{
                img{
                  width: 370px !important;
                  height: 280px !important;
                }
              }
            }
            @media (max-width: 540px) {
              &__image{
                img{
                  width: 340px !important;
                  height: 250px !important;
                }
              }
            }
            @media (max-width: 450px) {
              &__image{
                img{
                  width: 310px !important;
                  height: 220px !important;
                }
              }
            }
            @media (max-width: 400px) {
              &__image{
                img{
                  width: 280px !important;
                  height: 200px !important;
                }
              }
            }
            @media (max-width: 360px) {
              &__image{
                img{
                  width: 255px !important;
                  height: 175px !important;
                }
              }
            }
            @media (max-width: 340px) {
              &__image{
                img{
                  width: 225px !important;
                  height: 145px !important;
                }
              }
            }
            @media (max-width: 295px) {
              &__image{
                img{
                  width: 205px !important;
                  height: 125px !important;
                }
              }
            }
            h3{
              font-size: 24px;
              font-weight: 700;
              line-height: 30px;
              letter-spacing: 0em;
            }
            p{
              font-size: 16px;
              margin-top: 26px;
            }
            button{
              display: block;
            }
            
          }
          @media (max-width: 760px) {
            .newsCard {
              width: 80%;
              text-align: center;
              margin-bottom: 55px;
              height: 480px;
              &__image{
                margin: 0px;
                img{
                  width: 460px;
                  height: 270px;
                }
              }
              h3{
                font-size: 24px;
                font-weight: 700;
                line-height: 30px;
                letter-spacing: 0em;
              }
              p{
                font-size: 16px;
                margin-top: 26px;
              }
            }
          }
          @media (max-width: 630px) {
            .newsCard {
              width: 80%;
              text-align: center;
              margin-bottom: 55px;
              height: 520px;
            }
          }
          @media (max-width: 475px) {
            .newsCard {
              height: 470px;
              h3{
                font-size: 22px;
              }
              p{
                font-size: 16px;
              }
              .request{
                font-size: 16px;
              }
            }
          }
          @media (max-width: 375px) {
            .newsCard {
              height: 400px;
              h3{
                font-size: 19px;
                margin-top: 10px;
              }
              p{
                margin-top: 15px;
                font-size: 14px;
              }
              .request{
                font-size: 14px;
              }
            }
          }
          @media (max-width: 375px) {
            .newsCard {
              h3{
                font-size: 17px;
                margin-top: 7px;
              }
              p{
                margin-top: 10px;
                font-size: 13px;
              }
              .request{
                font-size: 12px;
              }
            }
          }
        }
      }
  }
  }
  @media (max-width: 1024px) {
    .background{
      .newsPage__container{
        .newsCard{
          height: 570px;
        }
      }
    }
}

@media (max-width: 450px) {
  .background{
    .newsPage__container{
      .newsCard{
        height: 500px;
      }
    }
  }
}
@media (max-width: 375px) {
  .background{
    .newsPage__container{
      .newsCard{
        height: 430px;
      }
    }
  }
}
@media (max-width: 340px) {
  .background{
    .newsPage__container{
      .newsCard{
        height: 380px;
      }
    }
  }
}