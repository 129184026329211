.contacts {
  width: auto;
  padding-top: 10px;

  &__header {
    margin-top: 94px;
    text-align: center;
  }
  
  &__content {
    display: flex;
    margin-top: 75px;
    padding-bottom: 75px;
    .contactSide {
      display: flex;
    }
    .map{
      width: 922px;
      height: 632px;
    }
   
  }
  .connections {
    margin-left: 95px;
    &__title {
      font-size: 25px;
      font-weight: 700;
      line-height: 30px;
    }
    &__text {
      font-size: 20px;
      font-weight: 500;
      line-height: 26px;
      margin-top: 85px;
      p {
        margin-top: 18px;
        span {
          font-weight: 700;
        }
        a {
          color: black;
        }
      }
    }
    &__p {
      margin-top: 65px;
      font-size: 20px;
      font-weight: 400;
      line-height: 26px;
    }
    &__vk {
      margin-top: 75px;
    }
    .tg{
      margin-left: 7px;
    }
  }
  @media (max-width: 1650px){
    &__content {
    .map{
      width: 832px;
      height: 592px;
    }
  }
  .connections {
    margin-left: 85px;
    &__text {
      margin-top: 55px;
      }
      &__p {
        margin-top: 45px;
      }
      &__vk {
        margin-top: 55px;
      }
    }
  
  }

  @media (max-width: 1535px){
    &__content {
    .map{
      width: 722px;
      height: 522px;
    }
    }
    .connections {
      margin-left: 75px;
      
      &__text {
        font-size: 18px;
        margin-top: 25px;
        }
        &__p {
          margin-top: 70px;
        }
        &__vk {
          margin-top: 70px;
        }
      }
  }
  @media (max-width: 1440px){
    &__content {
    .map{
      width: 702px;
      height: 512px;
    }
    }
    .connections {
      margin-left: 45px;
      &__title {
        font-size: 18px;
      }
      &__text {
        font-size: 16px;
        margin-top: 15px;
        }
        &__p {
          font-size: 16px;
          margin-top: 65px;
          }
        &__vk {
          margin-top: 65px;
        }
    }
  }
  @media (max-width: 1300px){
    &__content {
    .map{
      width: 662px;
      height: 482px;
    }
    }
    .connections {
      margin-left: 45px;
      &__text {
        margin-top: 15px;
        }
        &__p {
          margin-top: 50px;
          }
          
        &__vk {
          margin-top: 45px;
        }
    }
  }
  @media (max-width: 1190px){
    &__content {
    .map{
      width: 622px;
      height: 462px;
    }
    }
    .connections {
      margin-left: 45px;
      &__title {
        font-size: 16px;
      }
      &__text {
        font-size: 14px;
        margin-top: 15px;
        }
        &__p {
          font-size: 16px;
          margin-top: 30px;
          }
        &__vk {
          margin-top: 30px;
        }
    }
  }
  @media (max-width: 1070px){
    &__content {
    .map{
      width: 592px;
      height: 442px;
    }
    }
    .connections {
      margin-left: 35px;
      &__title {
        font-size: 16px;
      }
      &__text {
        font-size: 14px;
        margin-top: 15px;
        }
        &__p {
          font-size: 14px;
          margin-top: 15px;
          }
        &__vk {
          margin-top: 15px;
        }
    }
  }

}
@media (max-width: 1024px){
  .contacts{
    &__header{
      h2{
        margin-top: 10px;
        margin-bottom: 72px;
      }

     
    }
    &__map{
      margin-top: 72px;
      width: 592px;
      height: 442px;
      margin-bottom: 52px;
      margin: 0 auto;
    }
    @media (max-width: 720px) {
      &__map{
        width: 472px;
        height: 402px;
        .ymaps-2-1-79-map{
          width: 472px !important;
        height: 402px !important;
        }
      }
    }
    @media (max-width: 520px) {
      &__map{
        width: 432px;
        height: 372px;
        .ymaps-2-1-79-map{
          width: 432px !important;
        height: 372px !important;
        }
      }
    }
    @media (max-width: 475px) {
      &__header{
        margin-top: 60px;
        h2{
          margin-top: 10px;
          margin-bottom: 72px;
        }
      }
      &__map{
        width: 372px;
        height: 352px;
        .ymaps-2-1-79-map{
          width: 372px !important;
        height: 352px !important;
        }
      }
    }
    @media (max-width: 420px) {
      &__map{
        width: 342px;
        height: 312px;
        .ymaps-2-1-79-map{
          width: 342px !important;
        height: 312px !important;
        }
      }
    }
    @media (max-width: 360px) {
      &__map{
        width: 312px;
        height: 292px;
        .ymaps-2-1-79-map{
          width: 312px !important;
        height: 292px !important;
        }
      }
    }
    @media (max-width: 340px) {
      &__map{
        width: 272px;
        height: 262px;
        .ymaps-2-1-79-map{
          width: 272px !important;
        height: 262px !important;
        }
      }
    }
    @media (max-width: 300px) {
      &__map{
        width: 242px;
        height: 232px;
        .ymaps-2-1-79-map{
          width: 242px !important;
        height: 232px !important;
        }
      }
    }
    &__posttitle{
      margin-top: 150px; 
      font-size: 22px;
      font-weight: 700;
      line-height: 22px;
      margin-bottom: 49px;
      text-align: left;
    }
    @media (max-width: 475px) {
      &__posttitle{
        margin-top: 80px; 
        font-size: 16px;
        margin-bottom: 40px;
      }
    }
    @media (max-width: 375px) {
      &__posttitle{
        margin-top: 60px; 
        font-size: 14px;
        margin-bottom: 30px;
      }
    }
    &__connection{
      text-align: left;
    }
    .connection{
      a{
        color: black;
        text-align: left;
      }
      a:visited{
        color: black;
      }
      p{
        font-size: 18px;
        font-weight: 400;
        line-height: 19px;
        margin-top: 13px;
        span{
          font-size: 18px;
          font-weight: 700;
          line-height: 19px;
        }
      }
      @media (max-width: 475px) {
        p{
          font-size: 16px;
          margin-top: 13px;
          span{
            font-size: 16px;
          }
        }
      }
      @media (max-width: 375px) {
        p{
          font-size: 14px;
          margin-top: 13px;
          span{
            font-size: 14px;
          }
        }
      }
    }
    &__p{
      margin-top: 29px;
      text-align: left;
      margin-bottom: 45px;
      font-size: 18px;
    }
    .flex-icons{
      display: flex;
      align-items: center;
    }
    .connections__vk{
      margin-bottom: 72px;
     .tg{
      margin-left: 7px;
     } 
    }
    @media (max-width: 475px) {
      &__p{
        margin-top: 29px;
        margin-bottom: 35px;
        font-size: 16px;
      }
      .connections__vk{
        margin-bottom: 52px;
      }
    }
    @media (max-width: 375px) {
      &__p{
        margin-top: 29px;
        margin-bottom: 25px;
        font-size: 14px;
      }
      .connections__vk{
        margin-bottom: 42px;
        img{
          width: 48px;
        }
      }
    }
  }
  }

.ymaps-2-1-79-gototech {
  display: none !important;
}
