.requestBackground{
    width: 100vw;
    height: 100vh;
    position: fixed;
    left: 0;
    top: 0;
    z-index: 150;
    background: rgba(0,0,0,0.5);
    .requestClose{
      position: absolute;
      right: 32%;
      top: 11%;
      cursor: pointer;
    }
  .requestModal{
      height: auto;
      width: 26%;
      position: absolute;
      top: 15%;
      left: 37%;
      z-index: 10;
      opacity: 1;
      background-color: white;
      color: #3F3F3F;
      h2{
        font-size: 25px;
        font-weight: 700;
        line-height: 30px;
      }
      .requestContent{
        width: 90%;
        margin: 0 auto;
        > * {
          width: 100%;
        }
        &__title{
          margin-top: 41px;
        }
        &__undertitle{
          margin-top: 17px;
          margin-bottom: 31px;
          text-align: center;
          font-size: 20px;
          font-weight: 400;
          line-height: 26px;
        }
        &__name, &__phone{
          >*{
            width: 100%;
          }
          p{
            margin-top: 31px;
            font-size: 20px;
            font-weight: 400;
          }
          input{
            height: 58px;
            border: 0.5px solid #D1D1D1;
            background-color: #F2F2F2;
            font-size: 20px;
            padding-left: 20px;
            margin-top: 27px;
          }
        }
        &__button{
          margin-top: 42px;
          cursor: pointer;
        }
        &__agreement{
          margin-top: 17px;
          font-size: 15px;
          font-weight: 400;
        }
        &__more{
          margin-top: 32px;
          margin-bottom: 41px;
          text-align: center;
          cursor: pointer;
        }
        &__more:hover{
          text-decoration: underline;
        }
  
      }
  }
  @media (max-width: 1800px) {
    .requestModal{
      width: 32%;
      left: 33%;
    }
  }
  @media (max-width: 1350px) {
    .requestModal{
      width: 35%;
      left: 31%;
    }
  }
  @media (max-width: 1250px) {
    .requestModal{
      width: 38%;
      left: 29%;
    }
  }
  @media (max-width: 1250px) {
    .requestModal{
      width: 41%;
      left: 27%;
    }
  }
  @media (max-width: 1024px){
    .requestModal{
      width: 60%;
      left: 19%;
      h2{
        font-size: 21px !important;
      }
      h3{
        font-size: 17px !important;
      }
      div{
        >p{
          font-size: 17px !important;
        }
        input{
          font-size: 17px !important;
        }
      }
      button{
        font-size: 17px !important;
      }
      .requestContent__agreement{
        font-size: 13px !important;
      }
      .requestContent__more{
        font-size: 15px !important;
        a{
          color: #3F3F3F;
        }
        a:visited{
          color: #3F3F3F;
        }
      }
    }
    .requestClose{
      position: absolute;
      right: 17%;
      top: 8%;
      z-index: 160;
      cursor: pointer;
    }
  }
  @media (max-width: 620px){
    .requestModal{
      width: 75%;
      left: 12%;
      h2{
        font-size: 19px !important;
      }
      h3{
        font-size: 15px !important;
      }
      div{
        >p{
          font-size: 15px !important;
        }
        input{
          font-size: 15px !important;
        }
      }
      button{
        font-size: 15px !important;
      }
      .requestContent__agreement{
        font-size: 11px !important;
      }
      .requestContent__more{
        font-size: 13px !important;
      }
    }
    .requestClose{
      position: absolute;
      right: 9%;
      top: 8%;
      z-index: 160;
      cursor: pointer;
    }
  }
  @media (max-width: 475px){
    .requestModal{
      width: 85%;
      left: 7%;
    }
    .requestClose{
      right: 4%;
      top: 8%;
    }
  }
  @media (max-width: 375px){
    .requestModal{
      width: 90%;
      left: 5%;
      h2{
        font-size: 17px !important;
      }
      h3{
        font-size: 14px !important;
      }
      div{
        >p{
          font-size: 14px !important;
        }
        input{
          font-size: 14px !important;
        }
      }
      button{
        font-size: 14px !important;
      }

.requestContent__agreement{
        font-size: 10px !important;
      }
      .requestContent__more{
        font-size: 12px !important;
      }
    }
    .requestClose{
      right: 5%;
      top: 8%;
    }
  }
  }

  .imageBackground{
    width: 100vw;
    height: 100vh;
    position: fixed;
    left: 0;
    top: 0;
    background: rgba(0,0,0,0.5);
    .requestClose{
      position: absolute;
      right: 27%;
      top: 18%;
      z-index: 301;
      cursor: pointer;
    }
  .imageModal{
      width: 50%;
      height: auto;
      position: absolute;
      z-index: 300;
      top: 15%;
      left: 25%;
      right: 25%;
      opacity: 1;
      color: #3F3F3F;
      text-align: center;
      >img{
        width:100%;
      }
    }
  @media (max-width: 1024px) {
    .requestClose{
      position: absolute;
      right: 15%;
      top: 24%;
      z-index: 301;
      cursor: pointer;
    }
    .imageModal{
      width: 50%;
      height: auto;
      position: absolute;
      z-index: 300;
      top: 30%;
      left: 25%;
      right: 25%;
      opacity: 1;
      color: #3F3F3F;
      text-align: center;
      >img{
        width:100%;
      }
    }
  }
  @media (max-width: 768px) {
    .requestClose{
      position: absolute;
      right: 11%;
      top: 24%;
      z-index: 301;
      cursor: pointer;
    }
    .imageModal{
      width: 70%;
      height: auto;
      position: absolute;
      z-index: 300;
      top: 30%;
      left: 15%;
      right: 15%;
      opacity: 1;
      color: #3F3F3F;
      text-align: center;
      >img{
        width:100%;
      }
    }
  }
  @media (max-width: 500px) {
    .requestClose{
      position: absolute;
      right: 5%;
      top: 24%;
      z-index: 301;
      cursor: pointer;
    }
    .imageModal{
      width: 80%;
      height: auto;
      position: absolute;
      z-index: 300;
      top: 30%;
      left: 10%;
      right: 10%;
      opacity: 1;
      color: #3F3F3F;
      text-align: center;
      >img{
        width:100%;
      }
    }
  }

}
  


  .menuModal{
    width: 100vw;
    height: 100vh;
    position: fixed;
    right: -100vw;
    top: 0;
    z-index: 150;
    background-color: #F3F3F3;
    transition: all .3s;
    .menuClose{
      position: absolute;
      right: 5%;
      top: 2%;
      cursor: pointer;
    }
    .menuContent{
      margin-left: 40px;
      margin-top: 150px;
      a{
        p{
          color: black !important;
        }
      }
      a:visited{
        p{
          color: black !important;
        }
      }
      a:-webkit-any-link {
        color: #000 !important;
      }
      &__p{
        font-size: 35px;
        font-weight: 400;
        line-height: 30px;
        letter-spacing: 0em;
        text-align: left;
        margin: 70px;
      }
    }
    @media (max-width: 768px){
      .menuContent{
        margin-left: 20px;
        margin-top: 120px;
        &__p{
          font-size: 28px;
          margin: 50px;
        }
      }
    }
    @media (max-width: 425px){
      .menuContent{
        margin-left: 15px;
        margin-top: 120px;
        &__p{
          font-size: 22px;
          margin: 45px;
        }
      }
    }
    @media (max-width: 375px){
      .menuContent{
        margin-left: 5px;
        margin-top: 90px;
        &__p{
          font-size: 18px;
          margin: 40px;
        }
      }
    }
  }
  .menuModalactive{
    width: 100vw;
    height: 100vh;
    position: fixed;
    right: 0;
    top: 0;
    z-index: 330;
    background-color: #F3F3F3;
    transition: all .3s;
    a:visited {
      color: #000 !important;
    }
    a{
      color: #000 !important;
    }
    .menuClose{
      position: absolute;
      right: 5%;
      top: 2%;
      cursor: pointer;
    }
    .menuContent{
      margin-left: 40px;
      margin-top: 150px;
      &__p{
        font-size: 35px;
        font-weight: 400;
        line-height: 30px;
        letter-spacing: 0em;
        text-align: left;
        margin: 70px;
      }
      .mobilemenu__portfolio{
        margin-top: -50px;
        display: flex;
        img{
          margin-left: -20px;
        }
      }
    }
    @media (max-width: 768px){
      .menuContent{
        margin-left: 20px;
        margin-top: 120px;
        &__p{
          font-size: 28px;
          margin: 50px;
        }
      }
    }
    @media (max-width: 425px){
      .menuContent{
        margin-left: 15px;
        margin-top: 120px;
        &__p{
          font-size: 22px;
          margin: 45px;
        }
      }
    }
    @media (max-width: 375px){
      .menuContent{
        margin-left: 5px;
        margin-top: 90px;
        &__p{
          font-size: 18px;
          margin: 40px;
        }
      }
    }
  }
  
.requestModalPage{
  .newsCardHeader{
    position: fixed;
    top: 0;
  }
  .requestContentPage{
    width: 80%;
    margin: 0 auto;
    text-align: center;
    .success_files{
      color: green;
      text-align: center;
      font-size: 20px;
    }
    .unsuccess_files{
      color: red;
      text-align: center;
      font-size: 20px;
    }
    .requestContentPage__title{
      margin-top: 105px;
      font-size: 25px;
      font-weight: 700;
      line-height: 30px;
    }
    .requestContentPage__undertitle{
      margin-top: 35px;
      font-size: 20px;
      font-weight: 400;
      line-height: 26px;
    }
    .requestContentPage__name, .requestContentPage__email, .requestContentPage__phone, .requestContentPage__theme, .requestContentPage__service{
      input{
        width: 100%;
        height: 58px;
        border: 0.5px solid #D1D1D1;
        background-color: #F2F2F2;
        font-size: 20px;
        padding-left: 20px;
        margin-top: 25px;
        margin-bottom: 27px;
        border-radius: 3px;
      }
      @media (max-width: 600px) {
        input{
          font-size: 16px;
        }
      }
      @media (max-width: 425px) {
        input{
          font-size: 14px;
        }
      }
    }
    .input__file{
      width: 100%;
      height: 58px;
      border: 0.5px solid #D1D1D1;
      background-color: #F2F2F2;
      font-size: 20px;
      margin-top: 25px;
      margin-bottom: 27px;
      border-radius: 3px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      color: #7c7c7c;
      span{
        padding: 0 20px;
      }
      .success_files{
        color: rgb(26, 148, 26);
      }
      .label{
        cursor: pointer;
        max-height: 100%;
        .file{
          width: 20px;
          outline:0;opacity:0;pointer-events:none;user-select:none
        }
      }
      
    }
    @media (max-width: 768px) {
      .input__file{
        .label{
          padding-top: 4px;
          img{
          }
        }
      }
    }
    @media (max-width: 600px) {
    .input__file{
      font-size: 16px;
      .success_files{
        font-size: 14px;
        color: rgb(26, 148, 26);
        margin-left: -25px;
      }
      .label{
        cursor: pointer;
        max-height: 100%;
        .file{
          width: 20px;
          outline:0;opacity:0;pointer-events:none;user-select:none
        }
      }
    }
  }
  @media (max-width: 425px) {
    .input__file{
      font-size: 14px;
      .success_files{
        font-size: 12px;
        color: rgb(26, 148, 26);
        margin-left: -25px;
      }
      .label{
        cursor: pointer;
        max-height: 100%;
        .file{
          width: 20px;
          outline:0;opacity:0;pointer-events:none;user-select:none
        }
      }
    }
  }
  @media (max-width: 375px) {
    .input__file{
      font-size: 11px;
      .success_files{
        font-size: 11px;
        color: rgb(26, 148, 26);
        margin-left: -35px;
      }
      .label{
        cursor: pointer;
        max-height: 100%;
        padding-top: 2px;
        img{
          width: 13px;
          height: 13px;
        }
        .file{
          width: 20px;
          outline:0;opacity:0;pointer-events:none;user-select:none
        }
      }
    }
  }
  @media (max-width: 300px) {
    .input__file{
      font-size: 11px;
      .success_files{
        font-size: 9px;
        color: rgb(26, 148, 26);
        margin-left: -40px;
      }
      .label{
        cursor: pointer;
        max-height: 100%;
        padding-top: 2px;
        img{
          width: 12px;
          height: 12px;
        }
        .file{
          width: 20px;
          outline:0;opacity:0;pointer-events:none;user-select:none
        }
      }
    }
  }
    textarea{
      border-radius: 3px;
      min-width: 100%;
      max-width: 100%;
      max-height: 230px;
      min-height: 120px;
      height: 58px;
      border: 0.5px solid #D1D1D1;
      background-color: #F2F2F2;
      font-size: 20px;
      padding-left: 20px;
      padding-top: 20px;
      margin-top: 27px;
      margin-bottom: 25px;
    }
    p{
      text-align: left;
      font-size: 20px;
      font-weight: 400;
      line-height: 26px;
    }
    .requestContentPage__service{
      p{
        margin-bottom: 25px;
      }
      .service_disabled{
      }
      .custom-select{
        margin-bottom: 27px;
        &__control{
          border-color: #D1D1D1;
          box-shadow: 0 0 0 0 #D1D1D1 !important;
          text-align: left !important;
          background-color: #F2F2F2 !important;
          font-family: "Commissioner", sans-serif !important;
          font-size: 20px !important;
          padding-left: 10px !important;
          color: #3F3F3F !important;
        }
        @media (max-width: 600px) {
          &__control{
            font-size: 16px;
          }
        }
        &__control:focus{border-color: #D1D1D1;box-shadow: 0 0 0 1px #D1D1D1 !important;  }
        &__control:hover{ border-color: #D1D1D1;  box-shadow: 0 0 0 1px #D1D1D1 !important;}
        &__control:active{ border-color: #D1D1D1;  box-shadow: 0 0 0 1px #D1D1D1 !important;}
        &__menu{
          background-color: #F2F2F2 !important;
          text-align: left !important;
          color: #3F3F3F !important;
        }
        &__single-value{
          color: #3F3F3F !important;
        }
        @media (max-width: 600px) {
          &__single-value{
            font-size: 16px !important;
          }
          &__input-container{
            font-size: 16px !important;
          }
          &__placeholder{
            font-size: 16px !important;
          }
        }
        @media (max-width: 425px) {
          &__single-value{
            font-size: 14px !important;
          }
          &__input-container{
            font-size: 14px !important;
          }
          &__placeholder{
            font-size: 14px !important;
          }
        }
      }
    }
    .requestContentPage__button{
      margin-top: 35px;
      margin-bottom: 65px;
    }
    @media (max-width: 768px) {
      .requestContentPage__title{
        margin-top: 105px;
        font-size: 22px;
      }
      .requestContentPage__undertitle{
        margin-top: 20px;
        margin-bottom: 25px;
        font-size: 18px;
      }
      input{
        height: 48px;
        font-size: 16px;
        padding-left: 15px;
        margin-top: 15px;
        margin-bottom: 25px;
      }
      textarea{
        font-size: 16px;
        padding-left: 15px;
      }
      p{
        text-align: left;
        font-size: 16px;
      }
      .requestContentPage__button{
        margin-top: 25px;
        margin-bottom: 55px;
        font-size: 18px;
      }
    }
    @media (max-width: 375px) {
      .requestContentPage__title{
        margin-top: 105px;
        font-size: 18px;
      }
      .requestContentPage__undertitle{
        margin-top: 20px;
        margin-bottom: 25px;
        font-size: 16px;
      }
      input{
        width: 100%;
        height: 48px;
        border: 0.5px solid #D1D1D1;
        background-color: #F2F2F2;
        font-size: 14px;
        padding-left: 15px;
        margin-top: 15px;
        margin-bottom: 25px;
      }
      textarea{
        min-width: 100%;
        max-width: 100%;
        max-height: 210px;
        min-height: 100px;
        font-size: 14px;
      }
      p{
        text-align: left;
        font-size: 14px;
      }
      .requestContentPage__button{
        margin-top: 25px;
        margin-bottom: 55px;
        font-size: 14px;
      }
    }
  }
}