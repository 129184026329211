@import url("https://fonts.googleapis.com/css2?family=Montserrat:wght@400;500;700&family=Neucha&display=swap");

html {
  height: 100%;
}
body {
  height: 100%;
}

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  list-style: none;
  outline: none;
  text-decoration: none;
  font-family: "Commissioner", sans-serif;
}

p {
  display: block;
  margin-block-start: 0em;
  margin-block-end: 0em;
  margin-inline-start: 0px;
  margin-inline-end: 0px;
}

.Monrat400 {
  font-weight: 400;
  font-family: "Montserrat", sans-serif;
}

.Monrat500 {
  font-weight: 500;
  font-family: "Montserrat", sans-serif;
}

.Monrat700 {
  font-weight: 700;
  font-family: "Montserrat", sans-serif;
}

.Neucha400 {
  font-weight: 400;
  font-family: "Neucha", cursive;
}

@font-face {
  font-family: "NeoSansPro";
  src: url("../assets/fonts/NeoSansPro/NeoSansPro-Medium.ttf")
    format("truetype");
  font-style: normal;
  font-weight: normal;
}

.SansPro500 {
  font-weight: 500;
  font-family: "NeoSansPro";
}

.failed {
  color: #ec1f27;
  font-size: 16px;
  text-align: center;
}
