.equipment {
  background-color: #f4f4f4;
  width: auto;
  &__title {
    padding-top: 92px;
  }
  &__text {
    margin-top: 74px;
    font-size: 20px;
    font-weight: 400;
    line-height: 26px;
  }
  &__textLink {
    font-size: 20px;
    font-weight: 400;
    line-height: 26px;
    margin-top: 40px;
    .textLink {
      .download {
        border: 0;
        cursor: pointer;
        color: #ec1f27;
        border-bottom: 1px solid #ec1f27;
        font-size: 20px;
      }
    }
    a:visited {
      color: red;
    }
  }
  
  &__photos {
    display: flex;
    justify-content: space-between;
    margin-top: 95px;
    padding-bottom: 92px;
    .img__link {
      display: block;
      position: relative;
      width: 100%;
      cursor: pointer;
      img{
        height: 100%;
      }
      span {
          display: flex;
          flex-direction: column;
          align-items: flex-start;
          justify-content: center;
      }
      span {
        position: absolute;
        top: 3px;
        left: 0px;
        right: 0;
        bottom: 0;
        z-index: 5;
        background: rgba(0, 0, 0, .8);
        opacity: 0;
        color: #fff;
        transition: opacity .2s linear;
        text-align: left;
        width: 400px;
        cursor: default;
        >a{
            margin-top: 20px;
            font-size: 21px;
            font-weight: 700;
            line-height: 24px;
            margin: 0 auto;
            cursor: pointer;
            color: white;
        }
        >a:hover{
          color: #ec1f27;
        }
        >a:visited{
          color: #ec1f27;
        }
    }
    span:hover{
      opacity: 1;
    }
    }
  }
  @media (max-width: 1860px) {
    &__photos {
      img{
        width:340px;
        height:30%;
      }
      span{
        width:340px !important;
      }
    }
  }
  @media (max-width: 1600px) {
    &__photos {
      img{
        width:310px;
        height:30%;
      }
      span{
        width:310px !important;
      }
    }
  }
  @media (max-width: 1460px) {
    &__photos {
      img{
        width:280px;
        height:30%;
      }
      span{
        width:280px !important;
      }
    }
  }
  @media (max-width: 1460px) {
    &__photos {
      img{
        width:280px;
        height:30%;
      }
      span{
        width:280px !important;
      }
    }
  }
  @media (max-width: 1340px) {
    &__photos {
      img{
        width:240px;
        height:30%;
      }
      span{
        width:240px !important;
      }
    }
  }
  @media (max-width: 1210px) {
    &__photos {
      img{
        width:210px;
        height:25%;
      }
      span{
        width:210px !important;
      }
    }
  }
  @media (max-width: 1070px) {
    &__photos {
      img{
        width:190px;
        height:25%;
      }
      span{
        width:190px !important;
      }
    }
  }
  
  @media (max-width: 1024px) {
    &__title {
      margin-bottom: 54px;
    }
    &__text {
      text-align: left;
      line-height: 32px;
    }
    &__textLink {
      text-align: left;
    }
    @media (max-width: 425px) {
      &__title {
        padding-top: 82px;
        margin-top:-10px;
      }
      &__text {
        font-size: 16px;
        line-height: 32px;
        margin-top: -5px;
      }
      &__textLink {
        font-size: 16px;
        margin-top: 30px;
        margin-bottom: -10px;
      }
    }
    @media (max-width: 375px) {
      &__title {
        padding-top: 62px;
        margin-top:-10px;
      }
      &__text {
        font-size: 14px;
        line-height: 32px;
        margin-top: -10px;
      }
      &__textLink {
        font-size: 14px;
        margin-top: 30px;
        margin-bottom: -25px;
      }
    }
    &__photos {
      display: flex;
      flex-wrap: wrap;
      justify-content: center;
      margin-top: 95px;
      padding-bottom: 92px;
      img{
        width: 310px;
        margin: 15px;
      }
    }
  }


  @media (max-width: 760px) {
    &__photos {
      img{
        width: 260px;
        margin: 15px;
      }
    }
  }
  @media (max-width: 645px) {
    &__photos {
      img{
        width: 240px;
        margin: 13px;
      }
    }
  }
  @media (max-width: 595px) {
    &__photos {
      img{
        width: 220px;
        margin: 11px;
      }
    }
  }
  @media (max-width: 540px) {
    &__photos {
      img{
        width: 205px;
        margin: 10px;
      }
    }
  }
  @media (max-width: 500px) {
    &__photos {
      img{
        width: 180px;
        margin: 7px;
      }
    }
  }
  @media (max-width: 435px) {
    &__photos {
      img{
        width: 160px;
        margin: 5px;
      }
    }
  }
  @media (max-width: 380px) {
    &__photos {
      img{
        width: 140px;
        margin: 2px;
      }
    }
  }
  @media (max-width: 320px) {
    &__photos {
      img{
        width: 120px;
        margin: 2px;
      }
    }
  }

}
