.about {
  width: auto;
  padding-top: 25px;

  &__title {
    margin-top: 45px;
  }
  &__content {
    display: flex;
    justify-content: space-between;
    margin-top: 85px;
  }
  .content {
    &__list {
      margin-left: 65px;
      display: flex;
      flex-direction: column;
      justify-content: space-evenly;
      li {
        display: flex;
        align-items: center;
        span {
          margin-left: 15px;
          font-size: 20px;
          font-weight: 400;
          line-height: 26px;
          letter-spacing: 0em;
        }
      }
    }
    @media (max-width: 1650px) {
      &__image {
        img {
          width: 651px;
        }
      }
    }
    @media (max-width: 1450px) {
      &__image {
        img {
          width: 551px;
        }
      }
      &__list {
        li {
          span {
            font-size: 16px;
          }
        }
      }
    }
    @media (max-width: 1300px) {
      &__image {
        img {
          width: 471px;
        }
      }
      &__list {
        justify-content: center;
        margin-left: 45px;
        li {
          margin-top: 40px;
        }
      }
    }
    @media (max-width: 1200px) {
      &__image {
        img {
          width: 471px;
        }
      }
      &__list {
        li {
          span {
            font-size: 14px;
          }
        }
      }
    }
    @media (max-width: 1080px) {
      &__image {
        img {
          width: 431px;
        }
      }
      &__list {
        justify-content: center;
        margin-left: 30px;
        li {
          margin-top: 30px;
          span {
            font-size: 14px;
          }
        }
      }
    }

    @media (max-width: 590px) {
      &__list {
        margin-left: -15px;
        li {
          margin-top: 30px;
          span {
            font-size: 14px;
          }
        }
      }
    }
  }
  &__posttitle {
    font-size: 25px;
    font-weight: 700;
    line-height: 30px;
    letter-spacing: 0em;
    padding-bottom: 75px;
  }
  .postTitle {
    display: flex;
    margin-top: 65px;
    .redline {
      text-decoration: underline red;
      text-underline-offset: 6px;
      cursor: pointer;
    }
    &__phone {
      margin-left: 30px;
      display: flex;
      align-items: center;
      .phone {
        margin-left: 8px;
        a {
          color: black;
        }
      }
    }
  }
  @media (max-width: 1450px) {
    &__posttitle {
      font-size: 21px;
      padding-bottom: 65px;
    }
    .postTitle {
      display: flex;
      margin-top: 45px;
    }
  }
  @media (max-width: 1200px) {
    &__posttitle {
      font-size: 18px;
      padding-bottom: 45px;
    }
    .postTitle {
      display: flex;
      margin-top: 25px;
    }
  }
  @media (max-width: 1080px) {
    &__posttitle {
      font-size: 16px;
      padding-bottom: 35px;
    }
    .postTitle {
      display: flex;
      margin-top: 5px;
    }
  }
}
@media (max-width: 1024px) {
  .about {
    &__title {
      margin-top: 15px;
    }
    &__image {
      margin-top: 50px;
      margin-bottom: 53px;
      text-align: center;
      img {
        width: 678px;
      }
    }
    @media (max-width: 720px) {
      &__image {
        margin-top: 40px;
        margin-bottom: 35px;
        img {
          width: 431px;
        }
      }
    }
    @media (max-width: 540px) {
      .about__text {
        padding-bottom: 95px;
        h3 {
          font-size: 16px;
        }
        p {
          font-size: 16px;
        }
        ul {
          li {
            font-size: 16px;
          }
        }
      }
    }
    @media (max-width: 490px) {
      &__image {
        margin-top: 15px;
        img {
          width: 361px;
        }
      }
    }
    @media (max-width: 400px) {
      &__image {
        margin-top: 15px;
        margin-bottom: 23px;
        img {
          width: 321px;
        }
      }
    }
    @media (max-width: 375px) {
      .about__text {
        h3 {
          font-size: 14px;
        }
        p {
          font-size: 14px;
        }
        ul {
          li {
            font-size: 14px;
          }
        }
      }
    }
    @media (max-width: 360px) {
      &__image {
        margin-top: -10px;
        margin-bottom: 5px;
        img {
          width: 291px;
        }
      }
    }
    @media (max-width: 330px) {
      &__image {
        img {
          width: 261px;
          height: 250px;
        }
      }
    }
    @media (max-width: 295px) {
      &__image {
        text-align: center;
        img {
          width: 241px;
          height: 230px;
        }
      }
    }
    &__text {
      padding-bottom: 95px;
      h3 {
        text-align: left;
        font-size: 18px;
        font-weight: 600;
        line-height: 26px;
        letter-spacing: 0em;
      }
      p {
        margin-top: 23px;
        font-size: 18px;
        font-weight: 400;
        line-height: 32px;
        letter-spacing: 0em;
        text-align: left;
      }
      .text__ul {
        margin-top: 62px;
        margin-bottom: 25px;
      }
      li {
        list-style-type: disc;
        text-align: left;
        margin-left: 27px;
        font-size: 18px;
        font-weight: 400;
        line-height: 32px;
        letter-spacing: 0em;
      }
    }
    .postTitle {
      display: flex;
      margin-top: 65px;
      .redline {
        text-decoration: underline red;
        text-underline-offset: 6px;
      }
      &__phone {
        margin-left: 30px;
        display: flex;
        align-items: center;
        .phone {
          margin-left: 8px;
          a {
            color: black;
          }
        }
      }
    }
  }
}
.mobileAbout {
  &__title {
    font-size: 32px;
    margin-top: 30px;
  }
  &__image {
    margin-top: 45px;
  }
}
